import React from "react"
import { makeStyles,  } from "tss-react/mui"
import Typography from "@mui/material/Typography"
import Grid from "@mui/material/Grid"

const useStyles = makeStyles()(() =>
  ({
    body: {
      backgroundColor: "rgb(25, 71, 113)",
      flexGrow: 1,
      padding: 24,
    },
    paragraph: {
      textAlign: "center",
      color: "white",
      margin: 16,
    },
    iframe: {
      width: "100%",
    },
  })
)

export const Footer = () => {
  const { classes } = useStyles()

  return (
    <div className={classes.body}>
      <Grid
        container
        spacing={3}
        direction="row"
        justifyContent="center"
        alignItems="stretch"
      >
        <Grid item xs={12} md={3}>
          <Typography variant="subtitle2" className={classes.paragraph}>
            AEROPARK NANTES
          </Typography>
          <Typography variant="body2" className={classes.paragraph}>
            5 rue Charles Lindbergh
            <br />
            44340 Bourguenais
            <br />
            France
          </Typography>
          <Typography variant="body2" className={classes.paragraph}>
            02 40 48 76 31
          </Typography>
          <Typography variant="body2" className={classes.paragraph}>
            contact@aeropark-nantes.fr
          </Typography>
        </Grid>
        <Grid item xs={12} md={3}>
          <Typography variant="subtitle2" className={classes.paragraph}>
            NOTRE LOCALISATION
          </Typography>
          <iframe
            title="aeropark google map"
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10852.399257431789!2d-1.5970984!3d47.1558995!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xe6398db01ec21491!2zQcOpcm9wYXJr!5e0!3m2!1sfr!2sfr!4v1592241299387!5m2!1sfr!2sfr"
            width="600"
            height="200"
            className={classes.iframe}
            frameBorder="0"
            allowFullScreen={true}
            aria-hidden="false"
            tabIndex={0}
          ></iframe>
        </Grid>
        <Grid item xs={12} md={3}>
          <Typography variant="subtitle2" className={classes.paragraph}>
            SUIVEZ NOUS !
          </Typography>
          <iframe
            src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Faeroparknantes%2F&tabs=timeline&width=340&height=70&small_header=true&adapt_container_width=true&hide_cover=false&show_facepile=true&appId"
            width="340"
            height="70"
            style={{
              border: "none",
              overflow: "hidden",
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
            }}
            scrolling="no"
            frameBorder="0"
            allow="encrypted-media"
            title="Facebook Aeropark"
            allow-transparency="true"
          ></iframe>
          <Typography variant="subtitle2" className={classes.paragraph}>
            NOS PARTENAIRES
          </Typography>
          <Grid container spacing={3} direction="row" justifyContent="center">
            <Grid item xs={12} md={6}>
              <a href="http://www.hotel-bb.com/fr/hotels/nantes-aeroport.htm">
                <img
                  src="/aek/img/betb.png"
                  width="70"
                  height="70"
                  alt="Logo B&B hotels"
                  style={{
                    display: "block",
                    marginLeft: "auto",
                    marginRight: "auto",
                    width: "70 px",
                  }}
                />
              </a>
            </Grid>
            <Grid item xs={12} md={6}>
            <a href="https://cocoparks.io/">
                <img
                  src="/aek/img/cocoparks.png"
                  width="176"
                  height="70"
                  alt="Cocoparks - Votre compagnon du stationnement en ville, en temps réel"
                  style={{
                    display: "block",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                />
              </a>
            </Grid>
          </Grid>
        </Grid>
        <Typography variant="body2" className={classes.paragraph}>
          © Aéropark Nantes 2020
          <br />
          <a href="/#contact" style={{ color: "white" }}>
            Nous contacter
          </a>{" "}
          |{" "}
          <a href="/#testimonial" style={{ color: "white" }}>
            Avis client
          </a>{" "}
          |{" "}
          <a
            href="https://aeropark-documents.s3-eu-west-1.amazonaws.com/Conditions+ge%CC%81ne%CC%81rales+de+ventes+Ae%CC%81ropark.pdf"
            style={{ color: "white" }}
          >
            Conditions générales de vente
          </a>{" "}
          |{" "}
          <a
            href="https://aeropark-documents.s3-eu-west-1.amazonaws.com/Mentions+le%CC%81gales+%26+Politique+de+Confidentialite%CC%81+Ae%CC%81ropark.pdf"
            style={{ color: "white" }}
          >
            Mentions légales et politique de confidentialité
          </a>
        </Typography>
      </Grid>
    </div>
  )
}
