import React from 'react'
import { Layout } from '../../../common/theme/layout'
import { NavigationBar } from '../../../common/components/navigation-bar'
import { NavigationBarLink } from '../../../common/components/navigation-bar-link'
import { AboutSection } from './about-section'
import { AccessSection } from './access-section'
import { ContactSection } from '../../../common/components/landing/contact-section'
import { Footer } from './footer'
import { HeaderSection } from '../../../common/components/landing/header-section'
import { PriceSection } from '../../../common/components/landing/price-section'
import { MobileSection } from './mobile-section'
import { IReferencePrice } from '../../../../domain/usecases/reference-price'
import { IContact } from '../../../../domain/usecases/contact'
import { IQuotes } from '../../../../domain/usecases/quotes'

export type Props = {
    referencePrice: IReferencePrice
    contact: IContact
    quotes: IQuotes
}

export default ({ referencePrice, contact, quotes }: Props) => (
  <Layout>
      <NavigationBar>
          <NavigationBarLink to="/#top">Accueil</NavigationBarLink>
          <NavigationBarLink to="/#acces">Accès</NavigationBarLink>
          <NavigationBarLink to="/#apropos">A propos</NavigationBarLink>
          <NavigationBarLink to="/#tarifs">Tarifs & réservation</NavigationBarLink>
          <NavigationBarLink to="/#contact">Contact</NavigationBarLink>
          <NavigationBarLink
            to="/app"
            onClick={() => {
                window.open('/aas/document/Présentation AAS.pdf', '_blank')
            }}
          >
              Client professionnel ?
          </NavigationBarLink>
          <NavigationBarLink to="/app">Espace client</NavigationBarLink>
      </NavigationBar>

      {/* Page */}
      <div id="top" style={{ paddingTop: 90 }}>
          <HeaderSection id="header" quotes={quotes} />
          <AboutSection id="apropos" />
          <AccessSection id="acces" />
          <PriceSection id="tarifs" referencePrice={referencePrice} />
          <MobileSection id="mobile" />
          <ContactSection id="contact" contact={contact} />
          <Footer />
      </div>
  </Layout>
)
