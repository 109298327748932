import React from "react"
import { makeStyles,  } from "tss-react/mui"
import Typography from "@mui/material/Typography"
import { Theme } from "@mui/material/styles"
import { Container } from "@mui/material"

const useStyles = makeStyles()((theme: Theme) =>
  ({
    body: {
      backgroundColor: theme.palette.secondary.main,
      flexGrow: 1,
      padding: 24,
    },
    paragraph: {
      textAlign: "center",
      color: theme.palette.primary.main,
      margin: 16,
    },
    iframe: {
      width: "100%",
    },
  })
)

export const Footer = () => {
  const { classes } = useStyles()

  return (
    <div className={classes.body}>
      <Container>
        <Typography variant="subtitle1" className={classes.paragraph}>
          NOTRE LOCALISATION
        </Typography>
        <iframe
          width="100%"
          height="200"
          frameBorder="0"
          scrolling="no"
          src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=5%20rue%20Charles%20Lindbergh%2044340%20Bouguenais+(A%C3%A9roport%20Auto%20Service)&amp;t=&amp;z=13&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
        ></iframe>
        <Typography variant="body2" className={classes.paragraph}>
          © Aéroport Auto Service 2020
          <br />
          <a href="/#contact" style={{ color: "white" }}>
            Nous contacter
          </a>{" "}
          |{" "}
          <a
            href="https://aeropark-documents.s3.eu-west-1.amazonaws.com/Conditions+ge%CC%81ne%CC%81rales+de+ventes-AAS.pdf"
            style={{ color: "white" }}
          >
            Conditions générales de vente
          </a>{" "}
          |{" "}
          <a
            href="https://aeropark-documents.s3.eu-west-1.amazonaws.com/Mentions+le%CC%81gales-AAS.pdf"
            style={{ color: "white" }}
          >
            Mentions légales et politique de confidentialité
          </a>
        </Typography>
      </Container>
    </div>
  )
}
