import React, { useEffect, useState } from 'react'
import { Button, CircularProgress, Grid, TextField, Typography } from '@mui/material'
import { DatePicker, TimePicker } from '@mui/x-date-pickers'
import dayjs, { Dayjs } from 'dayjs'

import { navigateSafely } from '../../../utils/safe-navigation'
import { IQuotes } from '../../../../domain/usecases/quotes'

const DateTimePicker = ({ value, onChange, dateLabel, timeLabel }) => {
  return (
    <Grid container direction="row" spacing={2}>
      <Grid item xs={6}>
        <DatePicker
          label={dateLabel}
          inputFormat="DD/MM/YYYY"
          value={value}
          onChange={onChange}
          renderInput={params => <TextField {...params} variant="standard" />}
        />
      </Grid>
      <Grid item xs={6}>
        <TimePicker
          label={timeLabel}
          value={value}
          onChange={onChange}
          renderInput={params => <TextField {...params} variant="standard" />}
        />
      </Grid>
    </Grid>
  )
}

type Props = {
  quotes: IQuotes
}

export const AvailabilityCard = ({ quotes }: Props) => {
  const [loading, setLoading] = useState(false)
  const [from, setFrom] = useState<Dayjs | null>(null)
  const [to, setTo] = useState<Dayjs | null>(null)
  const [price, setPrice] = useState<string | null | undefined>(null)
  const [error, setError] = useState<string | null | undefined>(null)

  useEffect(() => {
    ;(async () => {
      if (from != null && from.isValid() && to != null && to.isValid()) {
        const now = dayjs()

        if (from <= now) {
          setPrice(null)
          setError('Date de dépôt invalide')
        } else if (to <= now || to <= from) {
          setPrice(null)
          setError('Date de reprise invalide')
        } else {
          try {
            setLoading(true)
            const response = await quotes.get({
              deposit: from.toDate(),
              pickup: to.toDate(),
              options: [],
              code: null,
            })

            setLoading(false)

            if (response.available) {
              const price = response?.remainingPrice

              setError(null)
              setPrice(price ? (price / 100).toFixed(2) : null)
            } else {
              setError(response?.error?.message)
              setPrice(null)
            }
          } catch (e) {
            setError('Une erreur est survenue, vérifier les dates et réessayer dans un instant.')
            setPrice(null)
          } finally {
            setLoading(false)
          }
        }
      }
    })()
  }, [from, to])

  function onReservationButtonClicked() {
    navigateSafely('/app', {
      state: {
        deposit: from?.toDate(),
        pickup: to?.toDate(),
      },
    })
  }

  return (
    <Grid container justifyContent="space-between" alignItems="center" columnSpacing={4} rowSpacing={2}>
      <Grid item xs={4}>
        <DateTimePicker value={from} onChange={setFrom} dateLabel="Date de dépôt" timeLabel="Heure de dépôt" />
      </Grid>
      <Grid item xs={4}>
        <DateTimePicker value={to} onChange={setTo} dateLabel="Date de reprise" timeLabel="Heure de reprise" />
      </Grid>
      <Grid item xs={2}>
        {loading ? (
          <CircularProgress color="secondary" />
        ) : (
          <Typography variant="h4" align="center" color="secondary">
            {(error != null || price == null ? '--' : price) + ' €'}
          </Typography>
        )}
      </Grid>
      <Grid item xs={2}>
        <Button variant="contained" color="secondary" fullWidth disabled={!!error} onClick={onReservationButtonClicked}>
          RÉSERVER
        </Button>
      </Grid>
      {error && (
        <Grid item xs={12}>
          {error}
        </Grid>
      )}
    </Grid>
  )
}
