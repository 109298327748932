import React from "react"
import { makeStyles,  } from "tss-react/mui"
import { Theme } from "@mui/material"

const useStyles = makeStyles()((theme: Theme) =>
  ({
    botmLine: {
      height: "3px",
      width: "60px",
      background: theme.palette.secondary.main,
      position: "relative",
      border: 0,
      margin: "20px 0 45px 0",
    },
    sectionTitle: {
      lineHeight: "20px",
      margin: 0,
      fontSize: "28px",
      fontWeight: 700,
      textTransform: "uppercase",
    },
  })
)

type Props = {
  title: string
}

export default function SectionTitle({title}: Props) {
  const { classes } = useStyles()

  return (
    <div>
      <h2 className={classes.sectionTitle}>{title}</h2>
      <hr className={classes.botmLine} />
    </div>
  )
}
