import React from 'react'
import { makeStyles,  } from 'tss-react/mui'

const useStyles = makeStyles()(() =>
  ({
    body: {
      backgroundColor: 'rgb(41, 48, 46)',
      padding: '60px 60px',
    },
    container: {
      maxWidth: '1184px',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    title: {
      lineHeight: '1.4',
      fontSize: '28px',
      color: 'white',
    },
  })
)

export const NewsSection = (props: any) => {
  const { classes } = useStyles()

  return (
    <div {...props} className={classes.body}>
      <div className={classes.container}>
        <h2 className={classes.title}>NOTRE ACTUALITÉ</h2>
      </div>
    </div>
  )
}
