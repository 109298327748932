import React from 'react'
import { makeStyles,  } from 'tss-react/mui'
import SectionTitle from '../../../common/components/landing/section-title'
import { Grid } from '@mui/material'

const useStyles = makeStyles()(() =>
  ({
    body: {
      padding: '60px 60px',
      background: '#f5f5f5',
    },
    container: {
      maxWidth: '1184px',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    iframeVideo: {
      width: '100%',
      border: '0',
    },
    testiDetails: {
      background: '#fff',
      padding: '14px 24px',
      marginBottom: '20px',
      boxShadow: '3px 3px 2px 0px rgba(0, 0, 0, 0.18)',
      position: 'relative',
      '&::after': {
        content: '""',
        position: 'absolute',
        width: '0',
        height: '0',
        borderStyle: 'solid',
        borderColor: 'transparent',
        borderLeft: '0',
        bottom: '-40px',
        left: '56px',
        borderTopColor: '#fff',
        borderWidth: '20px',
      },
      '&::before': {
        content: '""',
        position: 'absolute',
        transform: 'rotate(45deg)',
        width: '0px',
        height: '0px',
        bottom: '-30px',
        left: '45px',
        borderStyle: 'solid',
        borderWidth: '15px',
        borderColor: 'transparent',
        zIndex: '-1',
        boxShadow: '3px -13px 5px 0px rgba(0, 0, 0, 0.18)',
        borderLeft: '0',
      },
    },
    testiInfoName: {
      display: 'inline-block',
      lineHeight: '22px',
      fontWeight: 900,
      color: '#000',
      marginTop: '8px',
    },
    testiInfoCity: {
      display: 'block',
      lineHeight: '16px',
      fontWeight: 400,
    },
  })
)

export const TestimonialSection = (props: any) => {
  const { classes } = useStyles()

  return (
    <div {...props} className={classes.body}>
      <div className={classes.container}>
        <SectionTitle title="AVIS CLIENTS" />
        <p>Faites comme nos + de 16 000 clients satisfaits, confiez-nous votre véhicule on s’occupe de tout.</p>
        <Grid container spacing={2} direction="row" justifyContent="center" style={{ marginTop: 24 }}>
          <Grid item md={4} sm={4}>
            <div className={classes.testiDetails}>
              <p>
                Très bon parking soucieux de notre véhicule agréable de le retrouver sans impact, en plus très proche de
                l'aéroport avec 2 enfants de 3 et 6 ans ce parking est parfait nous reviendrons en toute confiance.
                Merci pour votre professionnalisme.
              </p>
            </div>
            <div>
              <h3 className={classes.testiInfoName}>Marc</h3>
            </div>
          </Grid>
          <Grid item md={4} sm={4}>
            <div className={classes.testiDetails}>
              <p>Parking très pratique et à 3 pas de l'aéroport pas de perte de temps. Top!</p>
            </div>
            <div>
              <h3 className={classes.testiInfoName}>Maryse</h3>
            </div>
          </Grid>
          <Grid item md={4} sm={4}>
            <div className={classes.testiDetails}>
              <p>
                A recommander, à quelques minutes à pied de l aéroport ; fonctionnement pour le dépôt du véhicule ou la
                récupération très facile ; nous réserverons à nouveau avec Aeropark
              </p>
            </div>
            <div>
              <h3 className={classes.testiInfoName}>Nicole</h3>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  )
}
