import React, {useState} from 'react'
import {makeStyles} from 'tss-react/mui'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import Input from '@mui/material/Input'
import Button from '@mui/material/Button'
import {Mail, Phone} from '@mui/icons-material'
import {isEmailValid} from '../../../utils/form-validators'
import {isAAS} from '../../../utils/web-site'
import SectionTitle from './section-title'
import {Grid} from '@mui/material'
import {IContact} from "../../../../domain/usecases/contact";

const useStyles = makeStyles()(() => ({
    body: {
        padding: '60px 60px',
        background: '#f5f5f5',
    },
    container: {
        maxWidth: '1184px',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    space: {
        marginTop: 24,
    },
    formGroup: {
        marginBottom: '15px',
    },
    inputContainer: {
        color: '#555555',
        backgroundColor: '#fff',
        backgroundImage: 'none',
        border: '1px solid #ccc',
        borderRadius: 0,
    },
    input: {
        display: 'block',
        width: '100%',
        height: '34px',
        padding: '1px 6px',
        fontSize: '14px',
        lineHeight: 1.42857143,
    },
    messageSent: {
        color: 'green',
        border: '1px solid green',
        display: 'none',
        textAlign: 'center',
        padding: '15px',
        fontWeight: 'bold',
        marginBottom: '15px;',
    },
    messageError: {
        color: 'red',
        display: 'none',
        border: '1px solid red',
        textAlign: 'center',
        padding: '15px',
        fontWeight: 'bold',
        marginBottom: '15px;',
    },
    infoIcon: {
        verticalAlign: 'bottom',
        marginRight: 24,
        maxWidth: 24,
    },
}))

type Props = {
    id: string
    contact: IContact
}

export const ContactSection = ({id, contact}: Props) => {
    const {classes} = useStyles()
    const [state, setState] = useState({
        name: '',
        email: '',
        subject: '',
        message: '',
        success: false,
        error: false,
    })

    const handleChange = (name: string) => (event: any) => {
        setState({...state, [name]: event.target.value})
    }

    function validateName(display: boolean = false): boolean {
        const value = state.name
        return state.name !== undefined && value !== null && ((display && value.length === 0) || value.length >= 4)
    }

    function validateSubject(display: boolean = false): boolean {
        const value = state.subject
        return value !== undefined && value !== null && ((display && value.length === 0) || value.length >= 8)
    }

    function validateEmail(display: boolean = false): boolean {
        const email = state.email

        if (display && email.length === 0) {
            return true
        }

        return isEmailValid(email)
    }

    function validateMessage(display: boolean = false): boolean {
        const value = state.message
        return state.name !== undefined && value !== null && ((display && value.length === 0) || value.length >= 1)
    }

    const submit = async () => {
        if (validateName() && validateEmail() && validateSubject() && validateMessage()) {
            try {
                await contact.send({
                    name: state.name,
                    email: state.email,
                    subject: state.subject,
                    message: state.message,
                })
                setState({
                    ...state,
                    name: '',
                    email: '',
                    subject: '',
                    message: '',
                    success: true,
                    error: false,
                })
            } catch (error) {
                console.error(error)
                setState({...state, success: false, error: true})
            }
        }
    }

    return (
        <div id={id} className={classes.body}>
            <div className={classes.container}>
                <SectionTitle title="NOUS CONTACTER"/>
                <Grid container>
                    <Grid item md={4} sm={4}>
                        {isAAS() ? (
                            <img src="/aas/img/logo.jpg" alt="logo" width="160" style={{marginLeft: 24}}/>
                        ) : (
                            <img src="/aek/img/logo-no-border.png" alt="logo" width="160" style={{marginLeft: 24}}/>
                        )}
                        <div className={classes.space}></div>
                        <img src="/img/pin.png" alt="information icon" className={classes.infoIcon}
                             style={{float: 'left'}}/>
                        <p>
                            5 rue Charles Lindbergh
                            <br/> 44340 Bouguenais
                        </p>
                        <div className={classes.space}></div>
                        <p>
                            <Phone className={classes.infoIcon}/>
                            +33 2 40 48 76 31
                        </p>
                        <div className={classes.space}></div>
                        <p>
                            <Mail className={classes.infoIcon}/>
                            {isAAS() ? 'contact@aeroport-auto-service.fr' : 'contact@aeropark-nantes.fr'}
                        </p>
                    </Grid>
                    <Grid item md={8} sm={8}>
                        <div>
                            <h3>Formulaire de contact</h3>
                            <div className={classes.space}></div>
                            <div className={classes.messageError} style={state.error ? {display: 'block'} : {}}>
                                votre message n'a pas pu être envoyé
                            </div>
                            <div>
                                <FormControl className={classes.formGroup} fullWidth error={!validateName(true)}>
                                    <Input
                                        className={classes.inputContainer}
                                        inputProps={{className: classes.input}}
                                        placeholder="Nom"
                                        value={state.name}
                                        onChange={handleChange('name')}
                                        aria-describedby="component-name-error"
                                    />
                                    {validateName(true) ? null : (
                                        <FormHelperText id="component-name-error">
                                            Ce champ doit comporter au moins 4 characters
                                        </FormHelperText>
                                    )}
                                </FormControl>
                                <FormControl className={classes.formGroup} fullWidth error={!validateEmail(true)}>
                                    <Input
                                        className={classes.inputContainer}
                                        inputProps={{className: classes.input}}
                                        placeholder="Email"
                                        value={state.email}
                                        onChange={handleChange('email')}
                                        aria-describedby="component-email-error"
                                    />
                                    {validateEmail(true) ? null : (
                                        <FormHelperText id="component-email-error">Adresse email non
                                            valide</FormHelperText>
                                    )}
                                </FormControl>
                                <FormControl className={classes.formGroup} fullWidth error={validateSubject(true)}>
                                    <Input
                                        className={classes.inputContainer}
                                        inputProps={{className: classes.input}}
                                        placeholder="Objet"
                                        value={state.subject}
                                        onChange={handleChange('subject')}
                                        aria-describedby="component-subject-error"
                                    />
                                    {validateSubject(true) ? null : (
                                        <FormHelperText id="component-subject-error">
                                            Ce champ doit comporter au moins 8 characters
                                        </FormHelperText>
                                    )}
                                </FormControl>
                                <FormControl className={classes.formGroup} fullWidth>
                                    <Input
                                        className={classes.inputContainer}
                                        inputProps={{style: {padding: '1px 6px'}}}
                                        multiline={true}
                                        rows={5}
                                        placeholder="Message"
                                        value={state.message}
                                        onChange={handleChange('message')}
                                    />
                                </FormControl>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    disabled={!validateName() || !validateEmail() || !validateSubject() || !validateMessage()}
                                    onClick={submit}
                                >
                                    Envoyer
                                </Button>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}
