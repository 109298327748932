import React from 'react'
import { makeStyles,  } from 'tss-react/mui'
import { Grid } from '@mui/material'
import { Link } from 'gatsby'

import SectionTitle from '../../../common/components/landing/section-title'
import ReservationStep from '../../../common/components/landing/reservation-step'

const useStyles = makeStyles()(() =>
  ({
    body: {
      padding: '140px 60px',
      background: 'white',
    },
    container: {
      maxWidth: '1184px',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    description: {
      textAlign: 'justify',
    },
    steps: {
      marginTop: 16,
    },
  })
)

type Props = {
  id?: string
}

export const AboutSection = ({ id }: Props) => {
  const { classes } = useStyles()

  return (
    <div id={id} className={classes.body}>
      <Grid container className={classes.container}>
        <Grid item md={6} sm={6}>
          <SectionTitle title="A propos" />
        </Grid>
        <Grid item md={12} sm={12}>
          <p className={classes.description}>
            <strong>Aéroport Auto Service</strong> vous propose un service de gardiennage au départ de l’Aéroport de
            Nantes Atlantique à 3 minutes à pied seulement des halls d’embarquement. Notre service fonctionne 24h/24 et
            7j/7 sur réservation obligatoire. Il vous suffit de déposer votre véhicule à l’accueil de notre parking
            sécurisé, et nous nous chargeons du reste pendant toute la durée de votre séjour. Pas besoin de navette,
            vous pouvez vous rendre aux halls d’embarquement à pied qui se situent à 300 mètres de notre parking.
          </p>
          <p>Partez l’esprit libre !</p>
        </Grid>
        <Grid item md={12} sm={12}>
          <a href="/aas/document/Présentation AAS.pdf" target="_blank">
            <p>Vous voyagez à titre professionnel ? Retrouvez tous les avantages en cliquant ici</p>
          </a>
        </Grid>
        <Grid item container direction="row" spacing={2} style={{ marginTop: 24 }}>
          <Grid item md={3} sm={3} xs={6} className={classes.steps}>
            <ReservationStep img="/aas/img/about-step-1.png" overlayColor="#ACBF7F">
              <p>
                about-step- Réservez votre place de parking <Link to="/app">en cliquant ici</Link> ou par téléphone au
                02 40 48 76 31.
                <br />
                <br />
                Ne tournez plus en rond pour chercher une place, la vôtre vous attend ici !
              </p>
            </ReservationStep>
          </Grid>
          <Grid item md={3} sm={3} xs={6} className={classes.steps}>
            <ReservationStep img="/aas/img/about-step-2.png" overlayColor="#0E4971">
              <p>
                À votre arrivée, nous faisons un état des lieux électronique de votre véhicule puis nous nous chargeons
                de le stationner.
                <br />
                <br />
                Ne vous occupez de rien vous êtes déjà en vacances, partez en toute sérénité votre véhicule est en
                sécurité !
              </p>
            </ReservationStep>
          </Grid>
          <Grid item md={3} sm={3} xs={6} className={classes.steps}>
            <ReservationStep img="/aas/img/about-step-3.png" overlayColor="#437D7E">
              <p>
                Le parking est situé à 300 mètres des halls d’embarquement.
                <br />
                Vous êtes à 3 minutes de vos vacances.
                <br />
                <br />
                Simple, rapide et sécurisé !
              </p>
            </ReservationStep>
          </Grid>
          <Grid item md={3} sm={3} xs={6} className={classes.steps}>
            <ReservationStep img="/aas/img/about-step-4.png" overlayColor="#4C6D7E">
              <p>À votre retour, nous vous attendons sur le parking pour vous restituer les clés de votre véhicule.</p>
            </ReservationStep>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}
