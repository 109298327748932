import {HttpStatusCode} from "axios";

import {HttpClient} from "../network/http-client";
import {UnexpectedError} from "../../domain/errors/unexpected-error";
import {Contact, IContact} from "../../domain/usecases/contact";

export class RemoteContact implements IContact {
    constructor(
        private readonly url: string,
        private readonly httpClient: HttpClient
    ) {
    }

    async send(params: Contact.Params): Promise<void> {
        const httpResponse = await this.httpClient.request({
            url: this.url,
            method: 'post',
            body: params,
        });

        switch (httpResponse.statusCode) {
            case HttpStatusCode.Ok:
                return;
            default:
                throw new UnexpectedError();
        }
    }
}
