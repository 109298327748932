import React from 'react'
import { makeStyles,  } from 'tss-react/mui'
import Typography from '@mui/material/Typography'
import { Grid } from '@mui/material'

const useStyles = makeStyles()(() =>
  ({
    body: {
      padding: '140px 60px',
      background: 'white',
      color: 'black',
    },
    container: {
      maxWidth: '1184px',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    column: {
      textAlign: 'center',
    },
    image: {
      margin: 32,
    },
  })
)

type Props = {
  id?: string
}

export const SummarySection = ({ id }: Props) => {
  const { classes } = useStyles()

  return (
    <div id={id} className={classes.body}>
      <Grid container direction="row" spacing={5} justifyContent="center" className={classes.container}>
        <Grid item md={4} sm={6} className={classes.column}>
          <Typography variant="h5" align="center">
            Parking sécurisé
          </Typography>
          <img src="/aek/img/camera.png" className={classes.image} alt="Caméra" width="96" height="96" />
          <Typography variant="body1" align="center">
            Parking privé et vidéo-surveillé 24/24h 7/7j à l’aéroport de Nantes Atlantique. Partez l’esprit libre !
          </Typography>
        </Grid>
        <Grid item md={4} sm={6} className={classes.column}>
          <Typography variant="h5" align="center">
            Proximité immédiate
          </Typography>
          <img src="/aek/img/closeBy.png" className={classes.image} alt="Caméra" width="96" height="96" />
          <Typography variant="body1" align="center">
            Parking le plus près de l’aérogare. Situé à 300m des halls d’embarquement, pas besoin de navette rejoignez
            l’aéroport en 3 min à pied seulement. Gagnez du temps !
          </Typography>
        </Grid>
        <Grid item md={4} sm={6} className={classes.column}>
          <Typography variant="h5" align="center">
            Tarifs économiques
          </Typography>
          <img src="/aek/img/cost.png" className={classes.image} alt="Caméra" width="96" height="96" />
          <Typography variant="body1" align="center">
            Parking pas cher et le plus près de l’aéroport. Réduisez votre budget en profitant de nos petits prix toute
            l’année. Faites des économies !
          </Typography>
        </Grid>
      </Grid>
    </div>
  )
}
