import React from 'react'
import { makeStyles,  } from 'tss-react/mui'
import { Link } from 'gatsby'
import { Grid } from '@mui/material'

import SectionTitle from '../../../common/components/landing/section-title'
import ReservationStep from '../../../common/components/landing/reservation-step'

const useStyles = makeStyles()(() =>
  ({
    body: {
      padding: '60px 60px',
      background: 'white',
    },
    container: {
      maxWidth: '1184px',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    description: {
      textAlign: 'justify',
    },
    steps: {
      marginTop: 16,
    },
  })
)

type Props = {
  id?: string
}

export const AboutSection = ({ id }: Props) => {
  const { classes } = useStyles()

  return (
    <div id={id} className={classes.body}>
      <Grid container className={classes.container}>
        <Grid item md={6} sm={6}>
          <SectionTitle title="A propos" />
        </Grid>
        <Grid item md={12} sm={12}>
          <p className={classes.description}>
            Il n’a jamais été aussi simple de trouver une place de parking.
            <br />
            <strong>Aéropark Nantes</strong> est le parking privé le plus proche de l’aéroport et à petit prix. À
            seulement 300m des halls d’embarquement, pas besoin de navette vous rejoignez l’aérogare en 3 min pied. Nous
            proposons des tarifs low cost toute l’année pour vous permettre de réduire votre budget parking tout en
            bénéficiant d’un service de qualité. Avec plus de 20 ans d’expertise dans la gestion de parking privé sur
            l’aéroport de nantes, nous avons développé un véritable gage de qualité. Nous sommes ouvert 24/24h 7/7j.
            <br />
            <br />
            Ne faites plus de compromis, optez pour la rapidité et la qualité à moindre coût !
          </p>
        </Grid>
        <Grid item container direction="row" spacing={2} style={{ marginTop: 24 }}>
          <Grid item md={3} sm={3} xs={6} className={classes.steps}>
            <ReservationStep img="/aek/img/about-step-1.png" overlayColor="#ACBF7F">
              <p>
                Réservez votre place de parking <Link to="/app">en cliquant ici</Link> ou par téléphone au 02 40 48 76
                31.
                <br />
                <br />
                Ne tournez plus en rond pour chercher une place, la vôtre vous attend ici !
              </p>
            </ReservationStep>
          </Grid>
          <Grid item md={3} sm={3} xs={6} className={classes.steps}>
            <ReservationStep img="/aek/img/about-step-2.png" overlayColor="#0E4971">
              <p>
                À votre arrivée, nous faisons un état des lieux électronique de votre véhicule puis nous nous chargeons
                de le stationner.
                <br />
                <br />
                Ne vous occupez de rien vous êtes déjà en vacances, partez en toute sérénité votre véhicule est en
                sécurité !
              </p>
            </ReservationStep>
          </Grid>
          <Grid item md={3} sm={3} xs={6} className={classes.steps}>
            <ReservationStep img="/aek/img/about-step-3.png" overlayColor="#437D7E">
              <p>
                Le parking est situé à 300 mètres des halls d’embarquement.
                <br />
                Vous êtes à 3 minutes de vos vacances.
                <br />
                <br />
                Simple, rapide et sécurisé !
              </p>
            </ReservationStep>
          </Grid>
          <Grid item md={3} sm={3} xs={6} className={classes.steps}>
            <ReservationStep img="/aek/img/about-step-4.png" overlayColor="#4C6D7E">
              <p>À votre retour, nous vous attendons sur le parking pour vous restituer les clés de votre véhicule.</p>
            </ReservationStep>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}
