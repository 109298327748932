import React, {useEffect, useState} from 'react'
import {makeStyles} from 'tss-react/mui'
import {Button, Grid} from '@mui/material'
import {navigateSafely} from '../../../utils/safe-navigation'
import SectionTitle from './section-title'
import {Theme} from '@mui/material/styles'
import {IReferencePrice, ReferencePrice} from 'domain/usecases/reference-price'

const useBlockStyles = makeStyles()((theme: Theme) => ({
        root: {
            background: 'transparent',
            border: '1px solid ' + theme.palette.primary.main,
            width: '100%',
            margin: '8px auto',
            overflow: 'hidden',
            WebkitBorderRadius: 4,
            MozBorderRadius: 4,
            borderRadius: 4,
            float: 'left',
        },
        title: {
            color: '#FEFEFE',
            textAlign: 'center',
            background: theme.palette.primary.main,
            lineHeight: '2.5',
            position: 'relative',
            textTransform: 'lowercase',
            fontSize: '24px',
            margin: 0,
        },
        content: {
            textAlign: 'center',
            color: theme.palette.primary.main,
            marginTop: '16px',
            marginBottom: '16px',
        },
        price: {
            fontSize: '3rem',
            fontWeight: 'bold',
            margin: 0,
        },
    })
)

const Block = ({title, price}: any) => {
    const {classes} = useBlockStyles()

    return (
        <div className={classes.root}>
            <h2 className={classes.title}>{title}</h2>
            <div className={classes.content}>
                <p className={classes.price}>
                    {price && typeof price === 'number' ? <span>{price / 100} €</span> : <span>...</span>}
                </p>
            </div>
        </div>
    )
}

const useStyles = makeStyles()(() => ({
    body: {
        padding: '60px 60px',
        background: 'white',
    },
    container: {
        maxWidth: '1184px',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    pricingTable: {
        width: '80%',
        margin: '0px auto',
        textAlign: 'center',
        padding: '10px',
        paddingRight: '0',
    },
    priceInfoMessage: {
        fontStyle: 'italic',
        fontSize: '12px',
        marginTop: '16px',
        color: 'gray',
    },
    reservationButton: {
        marginTop: 16,
        textTransform: 'uppercase',
    },
}))

const useReferencePrice = (referencePrice: IReferencePrice) => {
    const [state, setState] = useState<ReferencePrice.Model>({
        day1: null,
        day2: null,
        day3: null,
        day4: null,
        day5: null,
        day6: null,
        day7: null,
        extraDay: null,
    })

    // Fetch prices from API
    useEffect(() => {
        (async () => {
            try {
                const prices = await referencePrice.get();
                setState(prices)
            } catch (error) {
                console.error('Failed to load prices', error)
            }
        })();
    }, [referencePrice]);

    return state;
}

export type Props = {
    id: string,
    referencePrice: IReferencePrice,
}

export const PriceSection = ({id, referencePrice}: Props) => {
    const {classes} = useStyles()
    const prices = useReferencePrice(referencePrice)

    return (
        <div id={id} className={classes.body}>
            <div className={classes.container}>
                <SectionTitle title="TARIFS & RÉSERVATION"/>
                <p>
                    Le parking le plus près à moindre coût. Profitez de la sécurité et du confort à petit prix, payez
                    moins cher
                    grâce à nos petit prix toute l’année. Gagnez du temps et de l’argent !
                </p>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={3}>
                        <Block title="1 jour" price={prices.day1}/>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Block title="2 jours" price={prices.day2}/>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Block title="3 jours" price={prices.day3}/>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Block title="4 jours" price={prices.day4}/>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Block title="5 jours" price={prices.day5}/>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Block title="6 jours" price={prices.day6}/>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Block title="7 jours" price={prices.day7}/>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Block title="Jours suppl." price={prices.extraDay}/>
                    </Grid>
                </Grid>
                <div className={classes.pricingTable}>
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.reservationButton}
                        onClick={() => navigateSafely('/app')}
                    >
                        Réservation et devis en ligne
                    </Button>
                    <p className={classes.priceInfoMessage}>
                        Les tarifs peuvent varier selon la saisonnalité et les disponibilités
                    </p>
                </div>
            </div>
        </div>
    )
}
