import React from "react"
import { makeStyles,  } from "tss-react/mui"
import { Theme } from "@mui/material"

const useStyles = makeStyles()((theme: Theme) =>
  ({
    step: {
      width: "100%",
      display: "inline-table",
      position: "relative",
      verticalAlign: "middle",
    },
    stepImage: {
      display: "block",
      width: "100%",
      height: "auto",
      borderRadius: 5,
    },
    overlay: {
      position: "absolute",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      height: "100%",
      width: "100%",
      borderRadius: 5,
      padding: 10,
      color: "white",
      transition: ".5s ease",
      backgroundColor: "#ACBF7F",
      opacity: 0,
      textAlign: "justify",
      "&:hover": {
        opacity: 0.9,
      },
      [theme.breakpoints.up("md")]: {
        padding: 16,
      },
    },
  })
)

type Props = {
  img: any
  overlayColor: any
  children: React.ReactNode
}

export default ({ img, overlayColor, children }: Props) => {
  const { classes } = useStyles()

  return (
    <div className={classes.step}>
      <img src={img} className={classes.stepImage} alt="..." />
      <div
        className={classes.overlay}
        style={{ backgroundColor: overlayColor }}
      >
        {children}
      </div>
    </div>
  )
}
