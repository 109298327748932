import React from 'react'
import { Layout } from '../../../common/theme/layout'
import { NavigationBar } from '../../../common/components/navigation-bar'
import { NavigationBarLink } from '../../../common/components/navigation-bar-link'
import { AboutSection } from './about-section'
import { AccessSection } from './access-section'
import { ContactSection } from '../../../common/components/landing/contact-section'
import { Footer } from './footer'
import { HeaderSection } from '../../../common/components/landing/header-section'
import { NewsSection } from './news-section'
import { PriceSection } from '../../../common/components/landing/price-section'
import { SummarySection } from './summary-section'
import { TestimonialSection } from './testimonial-section'
import { IReferencePrice } from '../../../../domain/usecases/reference-price'
import { IContact } from '../../../../domain/usecases/contact'
import { IQuotes } from '../../../../domain/usecases/quotes'

export type Props = {
    referencePrice: IReferencePrice
    contact: IContact
    quotes: IQuotes
}

export default ({ referencePrice, contact, quotes }: Props) => (
  <Layout>
      {/* <Layout> */}
      <NavigationBar>
          <NavigationBarLink to="/#top">Accueil</NavigationBarLink>
          <NavigationBarLink to="/#acces">Accès</NavigationBarLink>
          <NavigationBarLink to="/#apropos">A propos</NavigationBarLink>
          <NavigationBarLink to="/#tarifs">Tarifs & réservation</NavigationBarLink>
          <NavigationBarLink to="/#avisclient">Avis client</NavigationBarLink>
          <NavigationBarLink to="/#contact">Contact</NavigationBarLink>
          <NavigationBarLink to="/app">Espace client</NavigationBarLink>
      </NavigationBar>

      {/* Page */}
      <div id="top" style={{ paddingTop: 90 }}>
          <HeaderSection id="header" quotes={quotes} />
          <SummarySection id="summary" />
          <AccessSection id="acces" />
          <AboutSection id="apropos" />
          <PriceSection id="tarifs" referencePrice={referencePrice} />
          <TestimonialSection id="avisclient" />
          <NewsSection id="news" />
          <ContactSection id="contact" contact={contact} />
          <Footer />
      </div>
  </Layout>
)
