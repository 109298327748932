import {IReferencePrice, ReferencePrice} from "../../domain/usecases/reference-price";
import {HttpClient} from "../network/http-client";
import {HttpStatusCode} from "axios";
import {UnexpectedError} from "../../domain/errors/unexpected-error";

export class RemoteReferencePrice implements IReferencePrice {
    constructor(
        private readonly url: string,
        private readonly httpClient: HttpClient
    ) {
    }

    async get(): Promise<ReferencePrice.Model> {
        const httpResponse = await this.httpClient.request({
            url: this.url,
            method: 'get',
        });

        switch (httpResponse.statusCode) {
            case HttpStatusCode.Ok:
                return {
                    day1: httpResponse.body['1d'],
                    day2: httpResponse.body['2d'],
                    day3: httpResponse.body['3d'],
                    day4: httpResponse.body['4d'],
                    day5: httpResponse.body['5d'],
                    day6: httpResponse.body['6d'],
                    day7: httpResponse.body['7d'],
                    extraDay: httpResponse.body['extraDay'],
                }
            default:
                throw new UnexpectedError();
        }
    }
}