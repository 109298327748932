import React from 'react'

import { default as AASIndex } from '../../../presentation/aas/components/landing'
import { default as AEKIndex } from '../../../presentation/aek/components/landing'
import { isAAS } from '../../../presentation/utils/web-site'
import { createRemoteReferencePrice } from '../usecases/remote-reference-price-factory'
import { createRemoteContact } from '../usecases/remote-contact-factory'
import { createRemoteQuotes } from '../usecases/remote-quotes-factory'

const LandingPage = isAAS() ? AASIndex : AEKIndex

const createLandingPage: React.FC = () => {
  const remoteReferencePrice = createRemoteReferencePrice()
  const remoteContact = createRemoteContact()
  const quotes = createRemoteQuotes()

  return <LandingPage referencePrice={remoteReferencePrice} contact={remoteContact} quotes={quotes} />
}

export default createLandingPage
