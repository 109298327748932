import React from 'react'
import { makeStyles,  } from 'tss-react/mui'
import SectionTitle from '../../../common/components/landing/section-title'
import { Grid } from '@mui/material'

const useStyles = makeStyles()(_theme =>
  ({
    body: {
      padding: '60px 60px',
      background: '#1c3156',
      color: 'white',
    },
    container: {
      maxWidth: '1184px',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    iframeVideo: {
      width: '100%',
      border: '0',
    },
  })
)

type Props = {
  id?: string
}

export const AccessSection = ({ id }: Props) => {
  const { classes } = useStyles()

  return (
    <div id={id} className={classes.body}>
      <Grid container className={classes.container}>
        <Grid item md={6} sm={6}>
          <SectionTitle title="ACCÈS" />
        </Grid>
        <Grid item md={8} sm={8} xs={12}>
          <iframe
            src="https://player.vimeo.com/video/151367680"
            width="640"
            height="360"
            frameBorder="0"
            allowFullScreen
            className={classes.iframeVideo}
            title="Video Aéropark"
          ></iframe>
        </Grid>
        <Grid item md={4} sm={4} xs={12} style={{ lineHeight: 1.8 }}>
          <p>
            Réservez, déposez votre véhicule et décollez !<br />
            Il n'a jamais été aussi simple de trouver une place.
            <br />
            <br />
            Arrivée depuis le périphérique :
          </p>
          <ul>
            <li>Prendre la sortie 51 Porte de Grand Lieu</li>
            <li>
              En arrivant sur le rond point face à l’aéroport, prendre la 3e sortie direction « Saint Aignan de Grand
              Lieu »
            </li>
            <li>Puis, au rond point suivant prendre la 1ère sortie en direction de « Saint Aignan de Grand Lieu »</li>
            <li>
              <strong>Aéropark Nantes</strong> se trouve sur votre gauche
            </li>
          </ul>
          <p>
            Déposez votre véhicule devant l’accueil et dirigez-vous vers l’aérogare situé à 300m seulement du parking.
          </p>
        </Grid>
      </Grid>
    </div>
  )
}
