import React from 'react'
import { makeStyles,  } from 'tss-react/mui'
import SectionTitle from '../../../common/components/landing/section-title'
import { Grid } from '@mui/material'

const useStyles = makeStyles()(_theme =>
  ({
    body: {
      padding: '60px 60px',
      background: 'rgb(254, 249, 245)',
      color: 'black',
    },
    container: {
      maxWidth: '1184px',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    image: {
      width: '100%',
      border: '0',
    },
  })
)

type Props = {
  id?: string
}

export const AccessSection = ({ id }: Props) => {
  const { classes } = useStyles()

  return (
    <div id={id} className={classes.body}>
      <Grid container className={classes.container}>
        <Grid item md={6} sm={6}>
          <SectionTitle title="ACCÈS" />
        </Grid>
        <Grid item md={8} sm={8} xs={12}>
          <img src="/aas/img/plan.png" className={classes.image} alt="Plan Aéroport Auto Service" />
        </Grid>
        <Grid item md={4} sm={4} xs={12} style={{ lineHeight: 1.8 }}>
          <p>Par le périphérique :</p>
          <ul>
            <li>Prendre la sortie 51 Porte de Grand Lieu</li>
            <li>
              En arrivant sur le rond point face à l’aéroport, prendre la 3e sortie direction « Saint Aignan de Grand
              Lieu »
            </li>
            <li>Puis, au rond point suivant prendre la 1ère sortie en direction de « Saint Aignan de Grand Lieu »</li>
            <li>
              <p>
                <strong>Aéroport Auto Service</strong> se trouve sur votre gauche
              </p>
            </li>
          </ul>
        </Grid>
      </Grid>
    </div>
  )
}
