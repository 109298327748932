import React from 'react'
import { makeStyles,  } from 'tss-react/mui'
import Grid from '@mui/material/Grid'
import { Theme } from '@mui/material/styles'
import Typography from '@mui/material/Typography'

const useStyles = makeStyles()((theme: Theme) =>
  ({
    body: {
      padding: '60px 0px',
    },
    image: {
      width: '100%',
      border: '0',
    },
    title: {
      color: theme.palette.primary.main,
    },
  })
)

type Props = {
  id?: string
}

export const MobileSection = ({ id }: Props) => {
  const { classes } = useStyles()

  return (
    <div id={id} className={classes.body}>
      <Grid container spacing={6} direction="row" justifyContent="center" alignItems="center">
        <Grid item md={2} sm={2} xs={6}>
          <img src="/aas/img/mobile-app.png" className={classes.image} alt="Application mobile Aéroport Auto Service" />
        </Grid>
        <Grid
          container
          item
          md={5}
          sm={5}
          xs={12}
          spacing={5}
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={12}>
            <Typography variant="h4" gutterBottom align="center">
              L'application Aéroport Auto Service est disponible
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" align="center">
              Retrouver l'application Aéroport Auto Service sur iOS and Android
            </Typography>
          </Grid>
          <Grid container item spacing={2} direction="row" justifyContent="center" alignItems="center">
            <Grid item xs={4}>
              <a href="https://apps.apple.com/app/id1609303558">
                <img
                  src="/aas/img/app-store-badge.svg"
                  className={classes.image}
                  alt="Application mobile Aéroport Auto Service"
                />
              </a>
            </Grid>
            <Grid item xs={4}>
              <a href="https://play.google.com/store/apps/details?id=fr.aeroportautoservice.client">
                <img
                  src="/aas/img/google-play-badge.png"
                  className={classes.image}
                  alt="Application mobile Aéroport Auto Service"
                />
              </a>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}
